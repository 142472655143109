// Remove styling from <ol> and <ul> lists.
.list-unstyled {
    margin-left: 0;
    padding-left: 0;
    list-style: none;
}

// Make oembeds responsive.
.responsive-embed {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;

    iframe,
    object,
    embed,
    video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

// Hide from humans, but not screen readers.
.visually-hidden {
    position: absolute;
    margin: -1px;
    border: 0;
    padding: 0;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0 0 0 0);
}
