.prefooter {
    background-color: get-color(grey--lighter);
    text-align: center;
    padding: 5rem 0;
    position: relative;
    overflow: hidden;

    p {
        color: get-color(black);
        font-size: 2.1rem;
        font-weight: 500;
        line-height: 3.2rem;
        letter-spacing: 0.105rem;
    }
}

.prefooter__img {
    position: absolute;
    object-fit: cover;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
}

.prefooter__text {
    position: relative;
    z-index: 1;
}
