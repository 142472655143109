button {
    background: inherit;
    margin: 0;
    padding: 0;
    border: 0;
}

.lbmx-button {
    display: flex;
    align-items: center;
    background-color: get-color(black);
    color: get-color(white);
    font-size: 1.8rem;
    font-weight: 300;
    letter-spacing: 0.09rem;
    line-height: 2.8rem;
    min-width: 13rem;
    min-height: 5.5rem;
    padding: 0 4rem;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    transition: all 0.25s;

    &:active,
    &:hover,
    &:focus {
        background-color: get-color(grey--light);
        color: get-color(black);
    }

    // Modifiers
    &--purple {
        @extend .lbmx-button;
        background-color: get-color(purple);

        &:active,
        &:focus,
        &:hover {
            background-color: get-color(purple--dark);
            color: get-color(white);
        }
    }
}

// Imitate styling of a regular <a> element.
.lbmx-button--plaintext {
    cursor: pointer;
    color: get-color(purple);
    display: inline-block;
    font-size: 1.8rem;
    letter-spacing: 0.045rem;
    line-height: 2.8rem;
    font-weight: 300;
    text-align: center;

    &:active,
    &:focus,
    &:hover {
        color: get-color(purple--dark);
        text-decoration: underline;
    }
}
