html {
    color: get-color(grey--dark);
    font-family: 'proxima-nova', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 300;
}

h1,
h5 {
    color: get-color(grey--darkest);
}

h2,
h3,
h4 {
    color: get-color(black);
}

h1 {
    font-size: 3.2rem;
    letter-spacing: 0.096rem;
    text-transform: uppercase;

    // Media Queries
    @media( min-width: $tablet ) {
        font-size: 4.6rem;
        letter-spacing: 0.138rem;
    }
}

h2 {
    font-size: 3.2rem;
}

h3 {
    font-size: 2.8rem;
    line-height: 3.4rem;
}

h4 {
    font-size: 2.4rem;
    line-height: 3.2rem;
    margin-bottom: 1rem;
}

h5 {
    font-size: 2.1rem;
}

p,
table,
ol,
ul {
    font-size: 1.8rem;
    letter-spacing: 0.045rem;
    line-height: 2.8rem;
    font-weight: 300;

    a {
        color: get-color(purple);
        text-decoration: none;

        &:focus,
        &:hover {
            color: get-color(purple--dark);
            text-decoration: underline;
        }
    }
}

p + p {
    margin-top: 4rem;
}

figcaption {
    font-size: 1.4rem;
    margin-top: 1.2rem;
}
