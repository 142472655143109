// --------------------------------------------------
// Variables
// --------------------------------------------------


// --------------------------------------------------
// Placeholders
// --------------------------------------------------
%footer-text {
    font-size: 1.4rem;
    line-height: 1.8rem;
    text-decoration: none;
}

// --------------------------------------------------
// Selectors
// --------------------------------------------------
.footer {
    display: flex;
    justify-content: space-between;
    padding: 2rem 0 3rem 0;

    * {
        @extend %footer-text;
    }

    // Media Queries
    @media( min-width: $tablet ) {
        padding: 4rem 0;
    }
}

.footer__nav {
    ul {
        @extend .list-unstyled;
        display: flex;

        a {
            @extend %footer-text;
            color: get-color(purple);

            &:focus {
                outline: 0;
            }
        }
    }

    // Media Queries
    @media(min-width: $mobile) and (max-width: #{$tablet - 1}) {

        ul {
            flex-direction: column;
        }
    }

    @media( min-width: $tablet ) {
        li + li {
            margin-left: 2.2rem;
        }
    }
}

.footer__logo {
    margin-top: auto;

    a {
        display: inline-flex;
    }

    svg {
        width: 10rem;
        margin-left: 0.6rem;
    }
}
