.block-gallery {
}

.block-gallery__carousel {

    img {
        margin: 0 0.2rem;
    }
}

.block-gallery__caption {
    margin-top: -1rem;
    text-align: center;
}

// NOTE: These styles below overwrite existing styling from Slick Slider library.

// Navigation dots.
.block-gallery__dots {
    position: static !important;
    order: 2;
    margin-top: 0.6rem;

    ul {
        position: static;

        li {
            button {
                background: get-color(grey) !important;
                border-radius: 50%;
                padding: 0 !important;
                height: 1rem !important;
                width: 1rem !important;
                text-indent: -9999px;
            }
        }

        // Active dot styling
        li.slick-active {
            button {
                background: get-color(black) !important;
            }
        }
    }
}

// Arrows.
.slick-arrow {
    background-image: url(../images/arrow.png) !important;
    background-color: get-color(grey--lighter) !important;
    background-size: cover !important;
    z-index: 1;

    &::before {
        display: none !important;
    }

    &.slick-disabled {
        display: none !important;
    }

    &.slick-prev {
        left: 0;
    }

    &.slick-next {
        transform: rotate(180deg);
        right: 0;
    }
}
