ul.main-menu {
    @extend .list-unstyled;
    display: flex;

    li {
        display: flex;

        a {
            @extend %header-menu-text;
        }
    }

    // For desktop header nav.
    &.is-horizontal {
        flex-direction: row;

        li + li {
            margin-left: 1rem;
        }
    }

    // For mobile nav.
    &.is-vertical {
        flex-direction: column;
        align-items: flex-end;

        li + li {
            margin-top: 1rem;
        }
    }
}
