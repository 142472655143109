.block-full-width-cta {
    position: relative;
    overflow: hidden;

    // Media Queries
    @media( min-width: $tablet ) {
        margin-bottom: 8rem;
    }
}

.block-full-width-cta__img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.block-full-width-cta__text {
    position: relative;
    z-index: 1;
    margin: 7rem auto;
    max-width: 49rem;
    text-align: center;

    h4 {
        display: block;
        background-color: get-color(white);
        font-weight: 400;
        margin: 0;
        opacity: 0.98;
        padding: 2.5rem 3rem;

        & + h4 {
            margin-top: 0.5rem;
        }
    }

    &__button {
        display: inline-flex;
        border: 0.1rem solid rgba(251,251,251,0.9);
        margin-top: 2rem;
        justify-content: center;
    }

    // Media Queries
    @media(min-width: $mobile) and (max-width: #{$tablet - 1}) {
        br {
            display: none; // No line breaks on mobile.
        }
    }

    // Media Queries
    @media( min-width: $tablet ) {
        .block-full-width-cta__text {
            margin: 7rem 0;

            &__button {
                min-width: 41rem;
            }
        }
    }

    @media( min-width: $desktop ) {
        margin: 10rem 0;
    }
}
