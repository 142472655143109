@import "utilities/functions"; // Import this first!
@import "utilities/helpers";
@import "utilities/layout";
@import "utilities/normalize";

@import "colors";

// Components
@import "footer";
@import "header";
@import "mobile-nav";
@import "prefooter";

// Elements
@import "elements/buttons";
@import "elements/forms";
@import "elements/text";

// Partials
@import "partial/block-agenda";
@import "partial/block-gallery";
@import "partial/block-full-width-cta";
@import "partial/block-wysiwyg";
@import "partial/main-menu";

// Page Templates
@import "page";
@import "page-invitations";
@import "page-speakers";
@import "page-testimonials";

// Vendor files
@import "vendor/slick.scss";
@import "vendor/slick-theme.scss";

* {
    box-sizing: border-box;
    outline-color: get-color(purple);

    // Focus styles
    &:focus {
        outline: 2px dotted get-color(purple);
        outline-offset: 2px;
    }

    // No focus outline on body text links
    main *:focus {
        outline: 0;
    }
}

html {
    font-size: 62.5%; // 1rem = 10px
    font-weight: 300;
}

// Ensure page takes up 100% screen height.
body {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    main {
        flex-grow: 1;
    }
}
