.mobile-nav {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    transition: opacity 0.3s;
    z-index: 9;

    &.is-visible {
        display: flex;
        opacity: 1;
    }

    body.admin-bar & {
        top: 4.6rem;
    }
}

.mobile-nav__overlay {
    background-color: get-color(black--transparent);
    flex-basis: 50%;
}

.mobile-nav__main {
    display: flex;
    flex-direction: column;

    background-color: get-color(white);
    flex-basis: 50%;
    min-width: 25rem;
    padding: 6.9rem 5% 4rem 5%;

    // Media Queries
    @media( min-width: $tablet ) {
        padding: 8.4rem 5% 4rem 5%;
    }
}

.mobile-nav__close {
    position: relative;
    height: 2rem;
    width: 2rem;
    margin-left: auto;
    margin-bottom: 5rem;
    cursor: pointer;

    &::before,
    &::after {
        content: '';
        width: 0.1rem;
        height: 100%;
        top: 0;
        position: absolute;
        left: 1rem;
        height: 2.1rem;
        background: get-color(grey--dark);

    }

    &::before {
        transform: rotate(45deg);
    }

    &::after {
        transform: rotate(-45deg);
    }
}
