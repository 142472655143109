// --------------------------------------------------
// Variables
// --------------------------------------------------


// --------------------------------------------------
// Placeholders
// --------------------------------------------------


// --------------------------------------------------
// Selectors
// --------------------------------------------------
.pg--invitations {
    margin-top: $margin-top-std;
    margin-bottom: $margin-bottom-std;
}

.pg--invitations__text {

    // Media Queries
    @media( min-width: $tablet ) {
        display: flex;
        justify-content: space-between;

        .left-col,
        .right-col {
            width: calc((100% - 9rem) / 2);
        }
    }
}

.pg--invitations__form {
    max-width: 55rem;
    margin: 5rem auto 0 auto;

    & > h2 {
        text-align: center;
    }

    // Media Queries
    @media( min-width: $tablet ) {
        margin-top: 6rem;
    }
}
