// --------------------------------------------------
// Variables
// --------------------------------------------------


// --------------------------------------------------
// Placeholders
// --------------------------------------------------


// --------------------------------------------------
// Selectors
// --------------------------------------------------
.pg--speakers {
    margin-top: $margin-top-std;
    margin-bottom: $margin-bottom-std;

    &__title {
        text-align: center
    }

    // Media Queries
    @media (min-width: $mobile) and (max-width: #{$tablet - 1 }) {
        &__title {
            margin-bottom: 0;
        }
    }
}

.speaker {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 94rem;
    padding: 5rem 0;

    &:not(:last-of-type) {
        border-bottom: 0.1rem solid get-color(grey--light);
    }

    // Media Queries
    @media( min-width: $tablet ) {
        flex-direction: row;
    }
}

.speaker__img {
    flex-shrink: 0;
    margin: 0 auto 2.5rem auto;
    width: 17.5rem;

    img {
        border: 0.1rem solid get-color(grey--lighter);
        width: 100%;
    }

    // Media Queries
    @media( min-width: $tablet ) {
        margin: 0 5rem 0 0;
    }
}

.speaker__text {
    overflow: hidden;

    h4 {
        margin: 0 0 2rem 0;
        text-align: center;
    }

    h5 {
        margin: 0 0 0.5rem 0;
    }

    &__logo {
        display: block;
        height: 8rem;
        margin: 1rem auto 0 auto;
    }

    // Media Queries
    @media( min-width: $tablet ) {

        h4 {
            margin: 0 0 1rem 0;
            text-align: left;
        }

        &__logo {
            margin: 1rem 0 0 0;
        }
    }
}

.bio-text {
    position: relative;

    // Biographical text.
    &__inner {

        p {
            &:first-of-type {
                margin-top: 0;
            }

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }

    // Read more & read less links.
    p.read-more-link,
    p.read-less-link {
        margin-top: 0;
    }

    p.read-more-link {
        background-color: get-color(white);
        position: absolute;
        bottom: 0;
        right: 0;
        margin: 0;
        background: white;
        padding-left: 20px;

        &::before {
            content: '...';
            position: absolute;
            left: 0.3rem;
        }
    }

}
