// --------------------------------------------------
// Variables
// --------------------------------------------------


// --------------------------------------------------
// Placeholders
// --------------------------------------------------


// --------------------------------------------------
// Selectors
// --------------------------------------------------
.page-title {
    color: get-color(grey--darkest);
    text-align: center;
    padding: 3rem 0;
    border-top: 0.1rem solid get-color(grey--light);
    border-bottom: 0.1rem solid get-color(grey--light);
    margin: 0 auto;
}

.page-banner-image {
    img {
        width: 100%;
    }
}
