// --------------------------------------------------
// Variables
// --------------------------------------------------


// --------------------------------------------------
// Placeholders
// --------------------------------------------------
%h3-header-block {
    max-width: 42rem;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5rem;
    margin-bottom: 3rem;
    position: relative;
    text-align: center;
    z-index: 1;
}

// --------------------------------------------------
// Selectors
// --------------------------------------------------
.testimonials {
    display: flex;
    flex-direction: column;
    background-color: get-color(grey--lighter);
}

.testimonials__inner-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 3rem;
}

.testimonials__intro {
    padding-top: $margin-top-std;
    padding-bottom: 6.2rem;

    p {
        &:first-of-type {
            margin-top: 0;
        }
        &:last-of-type {
            margin-bottom: 0;
        }
    }
}

.testimonial {
    background-color: get-color(white);
    box-shadow: 2px 2px 3px get-color(grey--light);
    margin-bottom: 3rem;
    padding: 7.5rem 4rem 4rem 4rem;
    position: relative;
    align-self: flex-start;
    width: 100%;

    p {
        color: get-color(grey--dark);
    }

    &::before {
        content: '“';
        position: absolute;
        top: 0;
        left: 4rem;
        background-color: get-color(grey--darkest);
        color: get-color(white);
        font-family: serif;
        font-weight: bold;
        font-size: 4rem;
        padding: 1rem 1.2rem 0 1rem;
    }

    // Media Queries
    @media( min-width: $tablet ) {
        width: calc((100% - 2.5rem) / 2);
    }

    @media( min-width: $desktop ) {
        width: calc((100% - 5rem) / 3);
    }
}

.testimonial__img {
    border: 0.1rem solid get-color(grey--lighter);
    display: block;
    border-radius: 50%;
    width: 9rem;
    margin: 0 auto 2.5rem auto;
}

.testimonials__read-more,
.testimonials__read-less {
    @extend .lbmx-button--plaintext;
    display: inline-block;
    margin: 0 auto 3rem auto;
    width: auto;
}

.testimonials__read-less {
    display: none; // Toggled by JS
}

.testimonial-stats {
    margin-top: $margin-top-std;
    margin-bottom: $margin-bottom-std;
    position: relative;

    h3 {
        @extend %h3-header-block;
    }
}

.testimonial-stats__inner-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 3rem 2.5rem 0.5rem;
    position: relative;
    z-index: 1;

    background-size: cover;
    background-position: center;

    // Media Queries
    @media( min-width: $tablet ) {
        padding: 5rem 2.5rem 2.5rem;
    }
    @media( min-width: $desktop ) {
        padding: 7rem 2.5rem 4.5rem;
    }
}

.testimonial-stats__bg-img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    object-fit: cover;
    width: 100%;
}

.stat {
    align-self: flex-start;
    background-color: get-color(white);
    margin-bottom: $grid-gap;
    padding: 3rem 4rem;
    text-align: center;
    width: 100%;

    h3 {
        display: inline-block;
        font-size: 4.8rem;
        font-weight: 400;
        margin: 0 2%;
    }

    p {
        display: inline;
        font-size: 2.1rem;
    }

    // Force text after h3 to new line.
    h3 + p {
        display: block;
        margin: 0;
    }

    // Media Queries
    @media( min-width: $tablet ) and ( max-width: $desktop ) {
        width: calc((100% - 2.5rem) / 2);
    }

    @media( min-width: $desktop ) {
        width: calc((100% - 5.1rem) / 3);
    }
}

.testimonial-videos {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: $margin-bottom-std;
    border-top: 0.1rem solid get-color(grey--light);

    &__cta-button {
        margin-top: 4rem;
    }

    &__header {
        @extend %h3-header-block;
        max-width: none;
        width: 100%;
    }

    &__single {
        width: 100%;

        &:not(:last-of-type) {
            margin-bottom: $grid-gap;
        }
    }

    // Media Queries
    @media( min-width: $tablet ) {
        &__single {
            width: calc(50% - #{$grid-gap});

            &:nth-child(even) {
                margin-right: $grid-gap;
            }
        }

    }
}
