// --------------------------------------------------
// Variables
// --------------------------------------------------


// --------------------------------------------------
// Placeholders
// --------------------------------------------------
%header-menu-text {
    color: get-color(grey--dark);
    font-size: 1.6rem;
    letter-spacing: 0.112rem;
    padding: 1.4rem;
    text-transform: uppercase;
    text-decoration: none;

    &:active,
    &:focus,
    &:hover {
        background-color: get-color(grey--lightest);
        outline: 0;
    }

    &:active,
    &:focus,
    &:hover,
    li.current-menu-item & {
        color: get-color(black);
    }
}

// --------------------------------------------------
// Selectors
// --------------------------------------------------
.header {
    background-color: get-color(white);
    border-bottom: 0.1rem solid get-color(transparent);
    z-index: 9;
    transition: 0.24s border-bottom;

    &.js-is-sticky {
        border-bottom: 0.1rem solid get-color(grey--light);
    }
}

.header__inner-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 4rem;
    padding-bottom: 3.5rem;

    header.js-is-sticky & {
        padding: 2rem 0;
    }
}

.header__top-image {
    width: 100%;
    height: 1.5rem;
    object-fit: cover;
}

.header__logo {

    img {
        width: 20rem;
    }

    // Media Queries
    @media( min-width: $tablet ) {

        img {
            width: 28rem;
        }
    }
}

.header__menu-button {
    width: 1.8rem;
    height: 1.3rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: get-color(transparent);
    position: relative;
    cursor: pointer;

    span {
        height: 0.1rem;
        width: 1.8rem;
        background: get-color( black );
    }

    // Media Queries
    @media( min-width: $desktop ) {
        display: none;
    }
}

.header__nav {
    display: none;

    // Media Queries
    @media( min-width: $desktop ) {
        display: block;
    }
}

// Screen reader skip link.
// Source: https://codepen.io/joe-watkins/pen/rjhiK
.skip-link {
    padding: 1rem;
    position: absolute;
    top: -4rem;
    left: 1rem;
    z-index: 1000;
    color: get-color(white);

    &:focus {
        position: absolute;
        top: 0;
        background: get-color(grey--darkest);
    }
}
