// Breakpoints.
$mobile: 0px;
$tablet: 768px;
$desktop: 1200px;

// Standard gap between elements.
$grid-gap: 2.5rem;

// Standard top/bottom element margin.
$margin-bottom-std: 8rem;
$margin-top-std: 5.6rem;


.wrapper {
    max-width: 120rem;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
}

.wrapper--narrow {
    @extend .wrapper;
    max-width: 75rem;
}

.wrapper--880 {
    @extend .wrapper;
    max-width: 88rem;
}

.wrapper--full {
    @extend .wrapper;
    max-width: none;
}
