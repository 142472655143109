// --------------------------------------------------
// Variables
// --------------------------------------------------
$form-padding--top: 1.2rem;
$form-padding--sides: 2rem;

// --------------------------------------------------
// Placeholders
// --------------------------------------------------
%form-text {
    font-size: 1.6rem !important;
    line-height: 1.9rem !important;
    font-weight: 300 !important;
}

// --------------------------------------------------
// Selectors
// --------------------------------------------------

// NOTE: These styles overwrite existing native form styling from ACF plugin.

.acf-fields {

    // General fields
    .acf-field {
        border-top: none !important;

        // Labels
        .acf-label {
            label {
                @extend %form-text;

                .acf-required {
                    color: unset;
                }
            }
        }

        // Inputs
        input[type="text"],
        input[type="email"],
        input[type="number"],
        input[type="tel"],
        input[type="url"],
        textarea,
        select {
            @extend %form-text;
            border: 1px solid get-color(grey--light);
            padding: $form-padding--top $form-padding--sides;

            &::placeholder {
                color: get-color(grey);
            }
        }

        textarea {
            resize: none;
        }

        // Custom styling for error messages.
        .acf-input {
            display: flex;
            flex-direction: column;

            .acf-error-message {
                order: 2;
                background: get-color(transparent);
                margin-bottom: 0;
                padding-left: 0;

                p {
                    @extend %form-text;
                    color: get-color(red);
                }

                & + .acf-input-wrap {
                    input {
                        border: 1px solid get-color(red);
                    }
                }
            }
        }
    }
}

// Submit button
.acf-form-submit {
    display: flex;
    flex-direction: column;

    input[type="submit"] {
        @extend .lbmx-button;
        margin: 3rem auto 0 auto;
    }

    .acf-spinner {
        margin: 3rem auto 0;
    }
}
