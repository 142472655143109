.block-agenda {
    margin-top: $margin-top-std;
    margin-bottom: $margin-bottom-std;
}

.block-agenda__dates {
    display: flex;
    justify-content: center;
    margin-bottom: 5rem;

    button {
        cursor: pointer;
        border: 0.1rem solid get-color(black);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        transition: 0.24s all;
        height: 7.5rem;
        width: 7.5rem;

        &.is-active {
            background-color: get-color(black);
            color: get-color(white);
        }

        & + button {
            margin-left: 1.8rem;
        }

        span {
            display: block;
            line-height: 1;

            &.day {
                font-size: 3.5rem;
            }

            &.month {
                font-size: 0.9rem;
                text-transform: uppercase;
            }
        }
    }

    // Media Queries
    @media( min-width: $tablet ) {
        button {
            height: 10rem;
            width: 10rem;

            span {
                &.day {
                    font-size: 6.1rem;
                }

                &.month {
                    font-size: 1.5rem;
                }
            }
        }
    }

    @media( min-width: $desktop ) {
        button {
            height: 12rem;
            width: 12rem;

            span {
                &.day {
                    font-size: 7.2rem;
                }

                &.month {
                    font-size: 1.8rem;
                }
            }
        }
    }
}

.block-agenda__schedule {
    display: none;

    &.is-active {
        display: block;
    }

    h2 {
        text-align: center;
        margin-bottom: 5.5rem;
    }

    table {

        tr {

            td {
                position: relative;
                vertical-align: top;
                width: auto;

                // Time columns
                &:first-child,
                &:nth-child(2n) {
                    text-align: right;
                    width: 13rem;
                    white-space: nowrap;
                }

                // Event name column
                &:nth-child(3n) {
                    padding-left: 5.5rem;
                }
            }

            // Make empty rows take up space.
            td:empty::after{
                content: "\00a0";
            }

            // Add dash between start & end times.
            td:first-child:not(:empty) {
                &::after {
                    content: '-';
                    position: absolute;
                    right: -3.4rem;
                }
            }
        }
    }

    // Media Queries
    @media (min-width: $mobile) and (max-width: #{$tablet - 1} ) {

        table {

            tr {

                td {

                    &:first-child {
                        width: 7.8rem;
                    }

                    // Hide end time.
                    &:nth-child(2n) {
                        display: none;
                    }
                }
            }
        }
    }
}
